h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $font-family;
}

h1,
.text-h1 {
  font: $font-h1;
  letter-spacing: 0em;
}

h2,
.text-h2 {
  font: $font-h2;
  letter-spacing: 0em;

  @include media-screen(xs) {
    font: $font-h2-sx;
  }
}

h3,
.text-h3 {
  font: $font-h3;
  letter-spacing: 0em;
}

h4,
.text-h4 {
  font: $font-h4;
  letter-spacing: 0em;
}

h5,
.text-h5 {
  font: $font-h5;
  letter-spacing: 0em;

  @include media-screen(xs) {
    font: $font-h5-sx;
  }
}

h6,
.text-h6 {
  font-size: $font-h6;
  letter-spacing: 0em;
}

.text,
.heading {
  &-title {
    font: $font-h2;
    color: $text-secondary;
    letter-spacing: 0em;

    @include media-screen(xs) {
      font: $font-h2-sx;
    }
  }

  &-subtitle {
    font: $font-subtitle;
    color: $text-grey;
    letter-spacing: 0em;

    @include media-screen(xs) {
      font: $font-subtitle-sx;
    }
  }

  &-content {
    font: $font-content;
    color: $text-grey;
    letter-spacing: 0em;

    @include media-screen(xs) {
      font: $font-content-sx;
    }
  }

  &-content-2 {
    font: $font-placeholder;
    color: $text-dark;

  }
  &-content-3 {
    font: $font-placeholder-normal;
    color: $text-dark;

  }

  //   &-body {
  //     //styleName: body standard;
  //     font-family: $font-family;
  //     font-size: $font-body;
  //     font-style: normal;
  //     font-weight: 500;
  //     line-height: 24px;
  //     letter-spacing: 0em;
  //   }

  //   &-content {
  //     //styleName: content;
  //     font-family: $font-family;
  //     font-size: $font-content;
  //     font-style: normal;
  //     font-weight: 500;
  //     line-height: 24px;
  //   }


  //   &-button {
  //     font-size: 0.875rem;
  //     font-weight: 500;
  //     line-height: 2.25rem;
  //     letter-spacing: 0.0892857143em;
  //     font-family: "Roboto", sans-serif;
  //     text-transform: uppercase;
  //   }

  //   &-body-link {
  //     //styleName: body link;;
  //     font-family: $font-family;
  //     font-size: $font-body;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 32px;
  //     letter-spacing: 0em;
  //   }

  //   &-overline {
  //     font-size: 0.75rem;
  //     font-weight: 500;
  //     line-height: 2rem;
  //     letter-spacing: 0.1666666667em;
  //     font-family: "Roboto", sans-serif;
  //     text-transform: uppercase;
  //   }
}

.text-spacing {
  &-2 {
    letter-spacing: 2px;
  }

  &-3 {
    letter-spacing: 3px;
  }

  &-4 {
    letter-spacing: 4px;
  }
}

.text {
  &-primary {
    color: $text-primary;
  }

  &-second {
    color: $text-secondary;
  }

  &-white {
    color: $text-white;
  }

  &-grey {
    color: $text-grey;
  }

  &-dark {
    color: $text-dark;

  }
}

.color {
  &-primary {
    color: $color-primary !important;
  }

  &-second,
  &-secondary {
    color: $color-secondary !important;
  }

  &-white {
    color: $color-white !important;
  }

  &-grey {
    color: $color-grey !important;
  }

  &-grey-weight {
    color: $color-grey-weight !important;
  }

  &-dark {
    color: $color-dark !important;
  }

  &-danger,
  &-dangerous {
    color: $color-danger !important;
  }
}


.font-italic {
  font-style: italic
}

.font-weight {
  &-thin {
    font-weight: 100;
  }

  &-light {
    font-weight: 300;
  }

  &-regular {
    font-weight: 400;
  }

  &-medium {
    font-weight: 500;
  }

  &-semibold {
    font-weight: 600;
    font-family: $font-family-semibold;
  }

  &-bold {
    font-weight: 700;
    font-family: $font-family-bold;
  }

  &-black {
    font-weight: 900
  }
}

.text-decoration {

  &-line-through {
    text-decoration: line-through;
  }

  &-none {
    text-decoration: none;
  }

  &-overline {
    text-decoration: overline;
  }

  &-underline {
    text-decoration: underline;

  }
}

.text-pre {

  white-space: pre;

  &-line {
    white-space: pre-line;
  }

  &-wrap {
    white-space: pre-wrap;
  }
}

.text {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-justify {
    text-align: justify;
  }

  &-start {
    text-align: start;
  }

  &-end {
    text-align: end;
  }

  &-wrap {
    white-space: normal;
  }

  &-no-wrap {
    white-space: nowrap;
  }

  &-break {
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-none {
    text-transform: none;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-lowercase {
    text-transform: lowercase;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-note {
    white-space: nowrap;
    font: normal normal 300 13px/24px $font-family;
    color: #95c515;
  }
}