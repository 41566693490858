.service {
  padding: 64px 0;

  @include media-screen(xs) {
    padding: 12px 0;
  }
  > .service-list .ant-spin-container > .ant-row {
    justify-content: center;
  }
}

.calendar {
  max-width: 513px;
  width: 100%;
  margin: 0 auto;

  .react-calendar {
    margin-bottom: 24px;
  }
}

.order-summary {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  // max-width: 690px;
  margin: 0 auto;

  @include media-screen(xs) {
    grid-template-columns: auto;
  }

  &__card {
    background-color: $color-white;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 5px;
    padding: 0;

    &-content {
      padding: 20px 12px;
    }
  }

  &__title {
    font: normal normal normal 16px/18px $font-family-semibold;
    color: $text-secondary;
  }

  &__subtitle {}

  .ant-select-selection-item {
    color: $color-primary;
  }

}
.auto-complete {
  input {
    border-color: transparent;
    border-right-width: 0;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px #00000029;
    color: #777777;
    font: normal normal normal 16px/24px "proxima-nova";
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    margin-bottom: 25px;
    margin-top: 5px;
  }
  input:focus {
    border-color: transparent;
    outline: none !important;
    box-shadow: none !important;
  }
  label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
}

.content-left-dalkia .order-summary__card-content .flex-justify-between .text-subtitle{ 
  width: 19rem;
  flex-basis: 85%;
}
 