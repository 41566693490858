.calendar {
  .react-calendar {
    border: none;
    border-color: transparent;
    border-radius: 10px;
    width: 100%;
    padding: 40px;
    font-family: $font-family;

    @include media-screen(xs) {
      padding: 20px 6px;
    }

    & * {
      text-transform: capitalize;
    }

    &__navigation {
      height: 22px;

      &__label {
        font: normal normal 600 18px/22px $font-family-bold;
        color: $color-primary;
      }

      button {
        &:disabled {
          background-color: transparent;
        }

        &:enabled {

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }
    }

    &__month-view {

      &__weekdays {
        font: normal normal normal 14px/17px $font-family;
        color: #777777;

        &__weekday {
          @include media-screen(xs) {
            padding: 0.5em 0;
          }
        }
      }

      &__weekNumbers {
        .react-calendar__tile {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.75em;
          font-weight: bold;
        }
      }

      &__days {
        &__day {
          &--weekend {
            color: $text-grey;
          }

          &--neighboringMonth {
            // color: rgb(117, 117, 117);
            color: #000000;
          }
        }
      }

      .react-calendar__tile {
        padding: 4px 12px;
        font: normal normal normal 20px/24px $font-family;
        text-align: center;

        @include media-screen(xs) {
          padding: 4px 0;
        }

        &:disabled> :only-child {
          background-color: transparent;
          color: $text-grey;
        }

        >:only-child {
          background-color: transparent;
          border-radius: 50%;
          display: block;
          width: 37px;
          height: 37px;
          line-height: 38px;
          margin: 0 auto;
        }

        &:disabled {
          background-color: transparent;

          >:only-child {
            background-color: transparent;
          }

        }

        &:enabled {
          background-color: transparent;

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }

        &--now {
          background: transparent;
          font-weight: bold;
          color: $color-secondary;

          &:enabled {

            &:hover,
            &:focus {
              background: transparent;
            }
          }
        }

        &--hasActive {
          $bgcolor: lighten($color-primary, 30%);
          background: transparent;

          >:only-child {
            background: $bgcolor;
          }

          &:enabled {

            &:hover,
            &:focus {
              background: transparent;

              >:only-child {
                background: lighten($bgcolor, 10%);
              }
            }
          }
        }

        &--active {
          $bgcolor: $color-primary;
          background: transparent;
          color: white;

          >:only-child {
            background: $bgcolor;
            color: white;
          }

          &:enabled {

            &:hover,
            &:focus {
              background: transparent;

              >:only-child {
                background: $bgcolor;
              }

            }
          }
        }
      }

    }

    &__year-view,
    &__decade-view,
    &__century-view {
      .react-calendar__tile {
        // padding: 2em 0.5em;
        font: normal normal normal 20px/24px $font-family;

        >:only-child {
          border-radius: unset;
          width: unset;
          height: unset;
          line-height: unset;
        }

        &:disabled {
          background-color: rgb(240, 240, 240);
        }

        &:enabled {

          &:hover,
          &:focus {
            background-color: rgb(230, 230, 230);
          }
        }

        &--now {
          $bgcolor: lighten($color-secondary, 10%);
          background: transparent;
          color: $bgcolor;
          font-weight: bold;

          &:enabled {

            &:hover,
            &:focus {
              background: lighten($bgcolor, 10%);
              background: transparent;
            }
          }
        }

        &--hasActive {
          $bgcolor: lighten($color-primary, 30%);
          background: $bgcolor;
          color: $color-dark;

          &:enabled {

            &:hover,
            &:focus {
              background: lighten($bgcolor, 10%);
            }
          }
        }

        &--active {
          $bgcolor: $color-primary;
          background: $bgcolor;
          color: $color-dark;

          &:enabled {

            &:hover,
            &:focus {
              background: lighten($bgcolor, 10%);
            }
          }
        }
      }
    }

    &--selectRange {
      .react-calendar__tile {
        &--hover {
          background-color: transparent;
        }
      }
    }
  }
}


/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
  .calendar .react-calendar__month-view .react-calendar__tile> :only-child {
    line-height: 40px;
  }
}

/* Microsoft Edge Browser 15+ - @supports method */
@supports (-ms-ime-align:auto) and (-webkit-text-stroke:initial) {
  .calendar .react-calendar__month-view .react-calendar__tile> :only-child {
    line-height: 40px;
  }
}

/* Microsoft Edge Browser 14+ - @supports method */
@supports (-ms-ime-align:auto) and (not (-ms-accelerator:true)) {
  .calendar .react-calendar__month-view .react-calendar__tile> :only-child {
    line-height: 40px;
  }
}

/* Microsoft Edge Browser 14 - @supports method */

@supports (-ms-ime-align:auto) and (not (-ms-accelerator:true)) and (not (-webkit-text-stroke:initial)) {
  .calendar .react-calendar__month-view .react-calendar__tile> :only-child {
    line-height: 40px;
  }
}