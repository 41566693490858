.agency {
  &__search {
    @extend .mb-6;
    display: flex;
    justify-content: flex-end;

    .input-search {
      width: 100% !important;
      max-width: 425px;
    }
  }

  &-card {
    @extend .mb-6;
    @extend .px-4;
    @extend .py-5;
    background: $color-white;
    box-shadow: 0px 1px 6px #00000029;
    border-radius: 5px;

    &__title {
      font: $font-h4;
      color: $text-dark;
    }

    &__subtitle {
      font: normal normal bold 12px/24px $font-family-semibold;
      color: $color-primary;
    }

    &__content {
      font: normal normal normal 12px/24px $font-family;
      color: $text-grey;

    }

    &__date {
      font: normal normal 600 16px/20px $font-family-semibold;
      color: $color-primary;

    }

    &__code {
      font: $font-h4;
      color: $color-primary;

    }
  }

}
.agency-detail {
  &-footer {
    color : #95C515 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center; 
    & a {
      text-decoration: underline;
    }
  }
}