// COLOR VARIABLES
$color-primary: #95C515;
$color-secondary: #E75012;
$color-white: #fff;
$color-dark: #1A2223;
$color-green: #BAE34D;
$color-grey: #DEDEDE;
$color-grey-weight: #A1A4AC;
$color-blue: #3481ff;

$color-danger: #E75012;

// FONT VARIABLES
$default-font-size: 16px;
$default-line-height: 1.5;

$font-family: 'proxima-nova';
$font-family-bold: 'proxima-nova-bold';
$font-family-semibold: 'proxima-nova-semibold';
$font-family-medium: 'proxima-nova-medium';

$font-h1: normal normal bold 40px/40px $font-family-bold;
$font-h2: normal normal bold 22px/27px $font-family-bold;
$font-h2-sx: normal normal bold 18px/22px $font-family-bold;
$font-h3: normal normal bold 20px/24px $font-family-bold;
$font-h4: normal normal 600 16px/24px $font-family-semibold;
$font-h5: normal normal 600 16px/20px $font-family-semibold;
$font-h5-sx: normal normal 600 14px/15px $font-family-semibold;
$font-h6: normal normal 600 14px/16px $font-family-semibold;
$font-input: normal normal normal 16px/24px $font-family;
$font-placeholder: italic normal normal 14px/20px $font-family;
$font-steps-title: normal normal normal 12px/15px $font-family;
$font-label: normal normal normal 16px/24px $font-family;
$font-subtitle: normal normal normal 12px/18px $font-family;
$font-subtitle-sx: normal normal normal 12px/15px $font-family;
$font-placeholder-normal: normal normal normal 14px/20px $font-family;
$font-content: normal normal normal 16px/20px $font-family;
$font-content-sx: normal normal normal 14px/15px $font-family;

$content-width: 108rem; //1080px

$text-primary: #5D4E60;
$text-secondary: #E75012;
$text-white: #fff;
$text-grey: #777777;
$text-dark: #1A2223;


$bg-primary: #EFEFEF;
$bg-secondary: #F2D0A9;
$bg-grey: #EFEFEF;
$bg-dark: #1A2223;


$border-primary: #777777;
$border-grey: #707070;



