.custom-steps {
  width: 100%;
  display: flex;

  &-item {
    position: relative;
    display: inline-block;
    flex: 1 1;
    vertical-align: top;

    &:last-child {
      .custom-steps-item-tail {
        width: calc(50% - 20px);
        left: 0;
        right: unset;
      }
    }

    &-container {
      outline: none;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    &-tail {
      position: absolute;
      top: -4px;
      left: calc(50% + 20px);
      width: calc(100% - 40px);

      &::after {
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: $color-grey;
        transition: background 0.3s;
        content: '';
      }
    }

    .custom-steps-item-icon {
      background-color: $color-grey;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      font: normal normal 600 10px/20px $font-family;
      text-align: center;
      transition: background-color 0.3s;
    }

    .custom-steps-item-title {
      font: $font-steps-title;
      letter-spacing: -0.36px;
      color: $text-dark;
    }
  }

  &-item-active {
    .custom-steps-item-icon {
      background: transparent linear-gradient(90deg, $color-primary 0%, $color-green 100%) 0% 0% no-repeat padding-box;
    }

    .custom-steps-item-title {
      font: $font-h5;
      letter-spacing: -0.48px;
      color: $text-secondary;
    }
  }
}
