@import 'header';
@import 'footer';


.layout {
  min-height: 100vh;
  overflow: hidden;
  @include media-screen(xs) {
    background: #EFEFEF;
  }
}

.main {
  background: $color-white;

  @include media-screen(xs) {
    background: #EFEFEF;
  }
}

.container {
  background: #EFEFEF;
  // @include media-screen(xs) {
  //   min-height: calc(100vh - 120px);
  // }
}

.section {
  @extend .px-4;
  max-width: $content-width;
  width: 100%;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;

  @include media-screen(xs) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.page-footer {
  background: $color-white;
}